<template>
  <section>
    <b-row class="d-flex align-items-center justify-content-end">
      <b-col
        class="d-flex align-items-center justify-content-end"
        cols="6"
        md="6"
      >
        <div>
          <div>
            <b-button
              variant="primary"
              @click="showAssignTraining()"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-50"
                size="16"
              />
              <span class="text-nowrap">Assign training seat</span>
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
    <vue-good-table
      mode="remote"
      class="position-static mt-1"
      :is-loading="isLoading"
      :columns="columns"
      :rows="rows"
      :rtl="isRtl"
      :search-options="{
        enabled: false,
      }"
      :pagination-options="{
        enabled: true,
        perPage: perPage,
      }"
      style-class="vgt-table striped"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Status -->
        <span v-if="props.column.field === 'status'">
          <b-badge :variant="STATUS_COLOR[getStatus(props.row)]">
            {{ getStatus(props.row) }}
          </b-badge>
        </span>
        <!-- Column: Action -->
        <div
          v-else-if="props.column.label === 'Action'"
          cols="12"
          md="2"
          class="vgt-center-align d-flex"
        >
        <b-button
          v-if="props.row.status_id === null"
          variant="outline-none"
          class="nav"
          style=""
        >
          <b-nav-item-dropdown class="btn-icon" >
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="20" />
            </template>
              <b-dropdown-item 
              
              link-class="d-flex align-items-center"
              @click="removeTraining(props.row)">
                <feather-icon
                  size="16"
                  icon="TrashIcon"
                  class="mr-50"
                />
                Unassign training
              </b-dropdown-item>
           
          </b-nav-item-dropdown>
        </b-button>
        </div>
        <!-- Column: Common -->
        <span v-else>
          <span>{{ props.formattedRow[props.column.field] }}</span>
        </span>
      </template>
      
      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <table-pagination
          :per-page="perPage"
          :total="total"
          @perPageChanged="
            (value) => props.perPageChanged({ currentPerPage: value })
          "
          @pageChanged="
            (value) => props.pageChanged({ currentPage: value })
          "
        />
      </template>
    </vue-good-table>
    <participant-assign-training
      v-if="showModal"
      :program="defaultProgram"
      :assigned-trainings="assignedTrainings"
      :show-modal="showModal"
      @trainingAssigned="onTrainingAssigned"
      @hiddenModal="hiddenModal"
    />
  </section>
</template>
    
<script>
import { BBadge, BButton, BCol, BRow, BDropdownItem, BNavItemDropdown } from "bootstrap-vue";
import { GOODTABLE_ISO_DATETIME_INPUT_FORMAT, GOODTABLE_LOCALISED_DATE_TIME_FORMAT, localeDateStringFromIsoDateTime } from "@/libs/utils";
import TablePagination from "@/views/components/pagination/TablePagination.vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store";
import { messageStatusDisplay, trainingStatus, trainingStatusDisplay } from '@models';
import { commsService, trainingsService } from '@/services';
import { mapGetters } from 'vuex';
import { STATUS_COLOR, makeErrorToast, makeSuccessToast } from "@/libs/utils";
import ParticipantAssignTraining from "./ParticipantAssignTraining.vue";
    
export default {
  name: 'ParticipantCommunications',
  components: {
    BBadge,
    BNavItemDropdown,
    BDropdownItem,
    VueGoodTable,
    TablePagination,
    ParticipantAssignTraining,
    BButton,
    BCol,
    BRow
  },
  props: {
    user: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      perPage: 10,
      page: 1,
      columns: [],
      rows: [],
      columnFilters: [],
      sort: [{field: "updated_at", type:'desc'}],
      total: 0,
      STATUS_COLOR,
      assignedTrainings: []
    };
  },
  computed: {
    ...mapGetters('programs',['defaultProgramId', 'defaultProgram', 'forceRefreshParticipantMatches']),
    isRtl() {
      return store.state.appConfig.isRTL;
    }
  },
  created() {
    this.columns = [
      {
        label: "Training Name",
        field: "course_name",
        filterOptions: {
          enabled: false,
          placeholder: "Search Name",
        },
      },
      {
        label: "Date started",
        field: "first_access_date",
        filterOptions: {
          enabled: false
        },
        sortable: false,
      },
      {
        label: "Last accessed",
        field: "last_access_date",
        filterOptions: {
          enabled: false
        },
        sortable: false,
      },
      {
        label: "Available",
        field: "available_start_date",
        filterOptions: {
          enabled: false
        },
        sortable: false,
      },
   
      {
        label: "Time spent",
        field: "spent",
        filterOptions: {
          enabled: false
        },
        sortable: false,
      },
    
      {
        
        label: "Status",
        field: "status",
        width: "16em",
        sortable: false,
      },
      {
        
        label: "Action",
        field: "action",
        width: "5em",
        sortable: false,
      }
    ];
    this.loadItems();
  },
  methods: {
    hiddenModal(isShow) {
      this.showModal = isShow;
    },
    showAssignTraining() {
      this.showModal = true;
    },
    onTrainingAssigned() {
      this.showModal = false;
      this.loadItems();
    },
    getStatus(row) {
      if (row.status_id === null) {
        return trainingStatusDisplay[trainingStatus.NOT_STARTED];
      }
      return trainingStatusDisplay[row.status_id];
    },
    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },
    
    onPerPageChange(params) {
      this.perPage = params.currentPerPage;
      this.loadItems();
    },
    
    onSortChange(params) {
      let field = params[0].field;
      let type = params[0].type;
      if (type !== 'none') {
        this.sort = [{ field: field, type: type }];
      } else {
        this.sort = [];
      }
      this.loadItems();
    },

    onColumnFilter() {
      this.loadItems();
    },
    spent(trainingDetail) {
      if (!trainingDetail) {
        return 'Not started';
      }

      const spentTime = trainingDetail.total_seconds_tracked || 0;

      if (spentTime === 0) {
        return '0 minute';
      }

      // Don't show if seconds less than 60
      if (spentTime < 60) {
        return '0 minute';
      }

      return `${Math.round(spentTime / 60)} minutes`;
    },
    async removeTraining(training) {
      console.log(training.id)
      try {
        const programId = this.$route.params.id;
        const userId = this.user.id;

        const data = {
          training_id: training.id
        };
        await trainingsService.removeUserTraining(programId, userId, data);
        this.$toast(makeSuccessToast('Training removed successfully.'));
        this.loadItems();
      } catch (e) {
        console.log(e);
        this.$toast(makeErrorToast(e));
      }
    },
    async loadItems() {
      try {
        const programId = this.$route.params.id;
        this.isLoading = true;
        const response = await trainingsService.getUserTrainings(
          programId,
          this.user.id,
          {
            page: this.page,
            perPage: this.perPage,
            columnFilters: this.columnFilters,
            sort: this.sort,
          }
        );
        this.total = response.data.total;
        this.rows = response.data.items.map(item => {
          return {
            ...item,
            first_access_date: item.program_training_users.length? this.formatDate(item.program_training_users[0].first_access_date): null,
            last_access_date: item.program_training_users.length? this.formatDate(item.program_training_users[0].last_access_date): null,
            available_start_date: item.scheduled_action?.absolute_date ? this.formatDate(item.scheduled_action?.absolute_date): null,
            spent: item.program_training_users.length? this.spent(item.program_training_users[0]): null,
            status_id: item.program_training_users.length? item.program_training_users[0].status_id: null,
            updated_at: this.formatDate(item.updated_at, true)
          };
        });
        this.assignedTrainings = this.rows.map(training => {
          return {
            id: training.id
          };
        });
      } catch (e) {
        console.log(e);
        this.$toast(makeErrorToast(e));
      } finally {
        this.isLoading = false;
      }           
    },
    formatDate(datetime) {
      if (datetime) {
        return localeDateStringFromIsoDateTime(datetime, true);
      }
    },
  },
};
</script>
    
<style lang="scss">
@import '@/assets/scss/vue/libs/vue-good-table.scss';
.nav .dropdown-toggle:not(.active)::after {
    background-image: none !important;
}
.mail-type {
  margin-right: 20px;
  margin-left: auto;
}
</style>
    